<template>
    <div>
      <!-- Navigation -->
      <navigation-bar></navigation-bar>
  
      <!-- Hauptinhalt -->
      <div class="container mt-4">
        <h2>Fuvarok áttekintése (hivatkozási szám szerint)</h2>
  
        <!-- Kopfbereich mit statischen Informationen -->
        <div class="order-info mb-3">
          <p><strong>Megrendelő:</strong> {{ headerData.megrendelo }}</p>
          <p><strong>Viszonylat:</strong> {{ headerData.felrako }} - {{ headerData.lerako }}</p>
          
          <p><strong>Cikk:</strong> {{ headerData.cikk }}</p>
          <p><strong>Belső hivatkozási szám:</strong> {{ headerData.belsoHivatkozas }}</p>
        </div>
  
              <!-- Filter -->
      <div class="filters mb-3">
        <input 
          type="text" 
          class="form-control filter-input" 
          v-model="filters.datum" 
          placeholder="Dátum szűrő éééé-hh-nn" 
        />
      </div>
  
       <!-- Tabelle für Daten -->
       <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Datum</th>
            <th>Mennyiség</th>
            
            <th>Rendszám</th>
            <th>Potkocsi</th>
            <th>Fuvarozo</th>
            <th>Felrakás napja</th>
            <th>Lerakás napja</th>
            <th>Fel súly</th>
            <th>Le súly</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="(order, index) in filteredOrderList" :key="order.az">
            <td>{{ index + 1 }}</td>
            <!-- Datum nur anzeigen, wenn es sich ändert -->
            <td v-if="shouldShow(order, index)">{{ order.m_datumtol }}</td>
            <td v-else></td>

            <!-- Mennyiség nur anzeigen, wenn Datum sich ändert -->
            <td v-if="shouldShow(order, index)">{{ order.m_suly }}</td>
            <td v-else></td>


            <td>{{ order.rendszam }}</td>
            <td>{{ order.potkocsi }}</td>
            <td>{{ order.fuvarozo }}</td>
            <td>{{ order.felrakas_napja || 'N/A' }}</td>
            <td>{{ order.lerakas_napja || 'N/A' }}</td>
            <td>{{ formatNumber(order.fel_suly) }}</td>
            <td>{{ formatNumber(order.le_suly) }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="2"><strong>Összesen:</strong></td>
            <td><strong>{{ totalMennyiseg }}</strong></td>
            <td colspan="5"></td>
            <td><strong>{{ formatNumber(totalFelSuly) }}</strong></td>
            <td><strong>{{ formatNumber(totalLeSuly) }}</strong></td>
          </tr>
        </tfoot>


      </table>
    </div>
  </div>
</template>
  
  <script>
  import axiosInstance from "@/axiosInstance";
  import { mapGetters } from "vuex";
  
  export default {
    data() {
      return {
        orderList: [], // Liste der Bestellungen
        headerData: {
          megrendelo: "",
          felrako: "",
          lerako: "",
          cikk: "",
          belsoHivatkozas: ""
        },
        filters: {
          datum: ""
        }
      };
    },
    computed: {
      ...mapGetters(["getorderData"]),
      filteredOrderList() {
        return this.orderList.filter(order => {
          return !this.filters.datum || order.m_datumtol === this.filters.datum;
        });
      },
      totalMennyiseg() {
      let sum = 0;
      this.filteredOrderList.forEach((order, index) => {
        if (index === 0 || this.filteredOrderList[index - 1].m_datumtol !== order.m_datumtol) {
          sum += Number(order.m_suly || 0);
        }
      });
      return sum;
    },
        totalFelSuly() {
        return this.filteredOrderList.reduce((sum, order) => sum + Number(order.fel_suly || 0), 0);
        },
        totalLeSuly() {
        return this.filteredOrderList.reduce((sum, order) => sum + Number(order.le_suly || 0), 0);
        }


    },
    mounted() {
      this.fetchOrderList();
    },
    methods: {
      async fetchOrderList() {
        try {
          const postData = {
            m_cegaz: this.getorderData.m_cegaz,
            m_megrendeloaz: this.getorderData.m_megrendeloaz,
            m_felrakoaz: this.getorderData.m_felrakoaz,
            m_lerakoaz: this.getorderData.m_lerakoaz,
            m_cikkaz: this.getorderData.m_cikkaz,
            belsoHivatkozas: this.getorderData.belsoHivatkozas
          };
  
          console.log("postData:", postData);
  
          const response = await axiosInstance.post("/megrendeles/hivatkozas_szam.php", postData);
  
          if (response.status === 200 && response.data.data) {
            this.orderList = response.data.data;
  
            // Setze die statischen Informationen
            if (this.orderList.length > 0) {
              const firstOrder = this.orderList[0];
              this.headerData.megrendelo = firstOrder.megrendelo;
              this.headerData.felrako = firstOrder.felrako;
              this.headerData.lerako = firstOrder.lerako;
              this.headerData.cikk = firstOrder.cikk;
              this.headerData.belsoHivatkozas = firstOrder.belsoHivatkozas;
            }
          } else {
            console.error("Fehler beim Abrufen der Daten:", response.data.message);
          }
        } catch (error) {
          console.error("API Fehler:", error);
        }
      },
      shouldShow(order, index) {//********************************************************** */
      if (index === 0) return true; // Immer den ersten Eintrag anzeigen
      return this.orderList[index - 1].m_datumtol !== order.m_datumtol; // Vergleiche mit dem vorherigen Eintrag
    } ,
    formatNumber(value) {
      value = Number(value || 0);
      return value > 0 ? value.toFixed(2) : "0"; // Zwei Nachkommastellen nur bei Werten > 0
    }  


    }
  };
  </script>
  
  <style scoped>

.order-info {
  background-color: #f9f9f9;
  padding: 5px; /* Weniger Padding, um den Abstand zu reduzieren */
  border: 1px solid #ddd;
  border-radius: 5px;
  line-height: 1.0; /* Reduzierter Zeilenabstand */
  font-size: 13px; /* Kleinere Schriftgröße */
  margin-bottom: 10px; /* Weniger Abstand zur Tabelle */
}

.order-info p {
  margin: 3px 0; /* Weniger Abstand zwischen den Absätzen */
}

.filters {
  display: flex;
  align-items: center; /* Zentriere das Eingabefeld vertikal */
  gap: 10px; /* Abstand zwischen Filterelementen */
  margin-bottom: 15px; /* Abstand zur Tabelle */
}

.filter-input {
  max-width: 300px;
  width: 100%;
  padding: 5px; /* Weniger Innenabstand */
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
 
}

.table {
  margin-top: 20px;
}

.table th,
.table td {
  text-align: center;
  vertical-align: middle;
  line-height: 1.0;
  font-size: 14px;
  padding: 4px; /* Weniger Innenabstand für kompaktere Darstellung */
}

.table tfoot tr {
  font-weight: bold;
  background-color: #f8f9fa;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2; /* Helle Hintergrundfarbe für ungerade Zeilen */
}

.table-striped tbody tr:hover {
  background-color: #e6e6e6; /* Hover-Effekt für Zeilen */
}

  </style>
  