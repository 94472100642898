<template>
    <div v-if="showMenu" class="context-menu" :style="{ top: menuY + 'px', left: menuX + 'px' }">
      <ul>
        <li @click="handleUpdateTipus">Magasabban priorizált rakodás</li>
        <li @click="handleUpdateTipus">Magasabban priorizált rakodás megszüntetése</li>
        <li @click="handleAddNewRow">Új fuvar hozzáadása</li>
        <li @click="handleDeleteRow">Fuvar sor törlése</li>
        <li @click="handleUpdateTipusTo62">Adott rendszám leadva</li>
        <li @click="handleUpdateTipusTo63">Adott rendszám törlése</li>
        <li @click="handleShowDatePicker">Áthelyezés másik napra</li>
        <li @click="handleCloseContextMenu">Kilép</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showMenu: Boolean,
      menuX: Number,
      menuY: Number,
      selectedRow: Object,
      selectedSide: Number,
      fsz: String
    },
    methods: {
      handleUpdateTipus() {
        this.$emit('updateTipus', this.selectedRow, this.selectedSide);
      },
      handleAddNewRow() {
        this.$emit('addNewRow', this.selectedRow.napifo_az, this.fsz);
      },
      handleDeleteRow() {
        this.$emit('deleteRow', this.selectedRow);
      },
      handleUpdateTipusTo62() {
        this.$emit('updateTipusTo62', this.selectedRow, this.selectedSide);
      },
      handleUpdateTipusTo63() {
        this.$emit('updateTipusTo63', this.selectedRow, this.selectedSide);
      },
      handleShowDatePicker() {
        this.$emit('showDatePicker');
      },
      handleCloseContextMenu() {
        this.$emit('closeContextMenu');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Hier kannst du die Styles für das Kontextmenü einfügen */
  .context-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1000;
  }
  .context-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .context-menu li {
    padding: 8px 12px;
    cursor: pointer;
  }
  .context-menu li:hover {
    background-color: #eee;
  }
  </style>
  