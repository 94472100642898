<template>
  <div>
    <navigation-bar></navigation-bar>

    <!-- Hauptinhalt -->
    <div class="container-fluid mt-2">

      <h1>
        <i class="fas fa-edit"></i> 
        Megrendelés szerkesztése
      </h1>

      <form id="editOrderForm" v-if="megrendeles" @submit.prevent="saveOrder">
          <!-- Megrendelő input group -->
      <div class="form-group row align-items-center">
        <label for="megrendelo_filter" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megrendelő</label>
        
        <!-- Schreibgeschütztes Eingabefeld -->
        <div class="col-sm-2 col-md-2">
          <input type="text" class="form-control" v-model="megrendeles.megrendelo" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
        </div>

        <!-- Dropdown zur Auswahl -->
        <div class="col-sm-2 col-md-2">
          <div class="dropdown w-100">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              Kiválasztás
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
              <li v-for="item in megrendeloOptions" :key="item.v_kod">
                <a class="dropdown-item" href="#" @click.prevent="selectMegrendelo(item)">{{ item.v_nev1 }}</a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Eingabefeld -->
        <div class="col-sm-1 col-md-1">
          <input type="text" class="form-control" id="megrendelo_filter" placeholder="Keresés">
        </div>
        
        <!-- Button -->
        <div class="col-sm-1 col-md-1">
          <button type="button" class="btn btn-secondary btn-sm" @click="fetchMegrendelo" style="width: 60px; text-align: center;">
            
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>

        <!-- Buttons für Standardwerte -->
        <div class="col-sm-3 col-md-3">
          <div class="btn-group">
            <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValue(1)">Viterra</button>
            <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValue(2)">Cargill</button>
            <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValue(3)">UBM Grain</button>
          </div>
        </div>
      </div>

<!-- Felrakó input group **************************************************************************************************-->
    <div class="form-group row align-items-center">
        <label for="felrako_filter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Felrakó</label>
        
        <!-- Schreibgeschütztes Eingabefeld -->
        <div class="col-sm-2 col-md-2">
          <input type="text" class="form-control" v-model="megrendeles.felrako" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
        </div>

        <!-- Dropdown zur Auswahl -->
        <div class="col-sm-2 col-md-2">
          <div class="dropdown w-100">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownFelrakoButton" data-bs-toggle="dropdown" aria-expanded="false">
              Kiválasztás
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownFelrakoButton">
              <li v-for="item in felrakoOptions" :key="item.tav_az">
                <a class="dropdown-item" href="#" @click.prevent="selectFelrako(item)">{{ item.tav_megn }}</a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Eingabefeld -->
        <div class="col-sm-1 col-md-1">
          <input type="text" class="form-control" id="felrako_filter" placeholder="Keresés">
        </div>

        <!-- Button -->
        <div class="col-sm-1 col-md-1">
          <button type="button" class="btn btn-secondary btn-sm" @click="fetchFelrako" style="width: 60px; text-align: center;">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>

        <!-- Buttons für Standardwerte -->
        <div class="col-sm-3 col-md-3">
          <div class="btn-group">
            <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValueFelrako(1)">Slo-Koper</button>
            <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValueFelrako(2)">H-Foktő</button>
            <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueFelrako(3)">H-Szabadegyháza</button>
          </div>
        </div>
      </div>

<!-- Lerakó input group  *********************************************************************-->
          <div class="form-group row align-items-center">
        <label for="lerako_filter" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Lerakó</label>
        
        <!-- Schreibgeschütztes Eingabefeld -->
        <div class="col-sm-2 col-md-2">
          <input type="text" class="form-control" v-model="megrendeles.lerako" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
        </div>

        <!-- Dropdown zur Auswahl -->
        <div class="col-sm-2 col-md-2">
          <div class="dropdown w-100">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownLerakoButton" data-bs-toggle="dropdown" aria-expanded="false">
              Kiválasztás
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownLerakoButton">
              <li v-for="item in lerakoOptions" :key="item.tav_az">
                <a class="dropdown-item" href="#" @click.prevent="selectLerako(item)">{{ item.tav_megn }}</a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Eingabefeld -->
        <div class="col-sm-1 col-md-1">
          <input type="text" class="form-control" id="lerako_filter" placeholder="Keresés">
        </div>

        <!-- Button -->
        <div class="col-sm-1 col-md-1">
          <button type="button" class="btn btn-secondary btn-sm" @click="fetchLerako" style="width: 60px; text-align: center;">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>

        <!-- Buttons für Standardwerte -->
        <div class="col-sm-3 col-md-3">
          <div class="btn-group">
            <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValueLerako(1)">H-Duna-földvár</button>
            <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValueLerako(2)">H-Szabad-egyháza</button>
            <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueLerako(3)">H-Kiskun-félegyháza</button>
          </div>
        </div>
      </div>


<!-- Szállítás kezdete input group ****************************************************************************************-->
<div class="form-group row align-items-center">
<label for="datum" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Szállítás kezdete</label>
<div class="col-md-2">
  <span class="position-absolute lock-icon">
    <i class="fas fa-lock text-secondary"></i> <!-- Schloss-Symbol -->
  </span>
  <input 
    type="date" 
    class="form-control pl-4" 
    id="datum" 
    v-model="megrendeles.m_datumtol" 
    readonly 
    style="width: 150px; text-align: center; margin-right: 4px;">
    </div>
    <div class="col-sm-1 col-md-1">
      <small class="text-muted ms-2">(nem módosítható)</small>
    </div>

</div>


<!-- Cikk input group ****************************************************************************************************-->
             <div class="form-group row align-items-center">
        <label for="cikk_filter" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Cikk</label>
        
        <!-- Schreibgeschütztes Eingabefeld -->
        <div class="col-sm-2 col-md-2">
          <input type="text" class="form-control" v-model="megrendeles.cikk" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
        </div>
        
        <!-- Dropdown zur Auswahl -->
        <div class="col-sm-2 col-md-2">
          <div class="dropdown w-100">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownCikkButton" data-bs-toggle="dropdown" aria-expanded="false">
              Kiválasztás
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownCikkButton">
              <li v-for="item in cikkOptions" :key="item.cikk_az">
                <a class="dropdown-item" href="#" @click.prevent="selectCikk(item)">{{ item.cikk_megn }}</a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Eingabefeld -->
        <div class="col-sm-1 col-md-1">
          <input type="text" class="form-control" id="cikk_filter" placeholder="Keresés">
        </div>

        <!-- Button -->
        <div class="col-sm-1 col-md-1">
          <button type="button" class="btn btn-secondary btn-sm" @click="fetchCikk" style="width: 60px; text-align: center;">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>

        <!-- Buttons für Standardwerte -->
        <div class="col-sm-3 col-md-3">
          <div class="btn-group">
            <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValueCikk(1)">szójadara</button>
            <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValueCikk(2)">kukorica</button>
            <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueCikk(3)">napradara</button>
          </div>
        </div>
      </div>

<!--   Mennyiség ***************************************************************************************************************-->
    <div class="form-group row align-items-center">
      <label for="mennyiseg" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Mennyiség</label>
      <div class="col-sm-2 col-md-2 position-relative">
        <span class="position-absolute lock-icon">
          <i class="fas fa-lock text-secondary"></i> <!-- Schloss-Symbol -->
        </span>
        <input type="number" 
          class="form-control pl-4" 
          id="mennyiseg" 
          v-model="megrendeles.m_suly"
          readonly
          placeholder="tonna" 
          max="175" 
          @input="checkMennyiseg"
          @focus="clear_to"
          style="width: 150px; text-align: center; margin-right: 4px;">
        </div>
        <div class="col-sm-1 col-md-1">            
          <small class="text-muted">(nem módosítható)</small>
      </div>
    </div>

<!--   km ***************************************************************************************************************-->
<div class="form-group row align-items-center">
  <label for="tavolsag" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Távolság</label>
  <div class="col-sm-2 col-md-2">
    <input type="number" class="form-control" id="tavolsag" v-model=megrendeles.m_km 
    placeholder="km" required @focus="clear_km">
  </div>
</div>


<!-- Felrakó hely ****************************************************************************************************-->
<div class="form-group row align-items-center">
<label for="felhely" class="col-form-label col-md-1 d-flex align-items-center">Felrakó hely</label>

<!-- Schreibgeschütztes Eingabefeld -->
<div class="col-md-2">
  <input type="text" class="form-control" v-model=megrendeles.fhely
   readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
</div>

 <!-- Dropdown zur Auswahl -->
 <div class="col-md-2">
  <div class="dropdown w-100">
    <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownFelhelyButton" data-bs-toggle="dropdown" aria-expanded="false">
      Kiválasztás
    </button>
    <ul class="dropdown-menu w-100" aria-labelledby="dropdownFelhelyButton">
      <li v-for="item in felhelyOptions" :key="item.hely_az">
        <a class="dropdown-item" href="#" @click.prevent="selectFelHely(item)">
          {{ item.hely_ceg }} - {{ item.hely_cim }} - {{ item.hely_megj }}
        </a>
      </li>
    </ul>
  </div>
</div>

<!-- Aktualisierungsbutton -->
<div class="col-md-1">
  <button type="button" class="btn btn-secondary btn-sm " @click="fetchFelhely" style="width: 60px; text-align: center;">
    <i class="fas fa-sync-alt"></i>
  </button>
</div>
</div>  

<!-- Lerakó hely -->
<div class="form-group row align-items-center">
<label for="lehely" class="col-form-label col-md-1 d-flex align-items-center">Lerakó hely</label>

<!-- Schreibgeschütztes Eingabefeld -->
<div class="col-md-2">
  <input type="text" class="form-control" v-model=megrendeles.lehely readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
</div>

 <!-- Dropdown zur Auswahl -->
 <div class="col-md-2">
  <div class="dropdown w-100">
    <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownLehelyButton" data-bs-toggle="dropdown" aria-expanded="false">
      Kiválasztás
    </button>
    <ul class="dropdown-menu w-100" aria-labelledby="dropdownLehelyButton">
      <li v-for="item in lehelyOptions" :key="item.hely_az">
        <a class="dropdown-item" href="#" @click.prevent="selectLeHely(item)">
          {{ item.hely_ceg }} - {{ item.hely_cim }} - {{ item.hely_megj }}
        </a>
      </li>
    </ul>
  </div>
</div>


<!-- Aktualisierungsbutton -->
<div class="col-md-1">
  <button type="button" class="btn btn-secondary btn-sm" @click="fetchLehely"  style="width: 60px; text-align: center;">
    <i class="fas fa-sync-alt"></i>
  </button>
</div>
 
</div>


<!-- határidő ************************************************************************************************************ -->
<div class="form-group row align-items-center">
<label for="hatarido" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Szállítás határideje</label>
<div class="col-md-2 position-relative">
  <span class="position-absolute lock-icon">
    <i class="fas fa-lock text-secondary"></i> <!-- Schloss-Symbol -->
  </span>
  <input 
    type="date" 
    class="form-control pl-4" 
    id="hatarido" 
    v-model="megrendeles.szall_hatarido" 
    readonly 
    style="width: 150px; text-align: center; margin-right: 4px;">
  </div>
  <div class="col-sm-1 col-md-1">   
    <small class="text-muted">(nem módosítható)</small>
</div>
</div>



<!-- hivatkozási szám ************************************************************************************************************ -->
<div class="form-group row align-items-center">
<label for="hivatkozasi_szam" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Hivatkozási sz.</label>
<div class="col-md-2">
  <input type="text" class="form-control" id="hivatkozasi_szam" v-model=megrendeles.hivatkozasi_szam>
</div>
</div>

<!-- gmp  -->
<div class="form-group row align-items-center">
<label for="gmp" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">GMP</label>
<div class="col-md-2">
  <input type="checkbox" id="gmp" v-model="gmpValue">
</div>
</div>

<!-- Megrendelői ártípus -->
<div class="form-group row align-items-center">
<label for="tip_filter" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megr. ártípus</label>

<!-- Schreibgeschütztes Eingabefeld -->
<div class="col-sm-3 col-md-2">
  <input type="text" class="form-control" v-model=megrendeles.m_egysegarme readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
</div>

  <!-- Dropdown zur Auswahl -->
  <div class="col-sm-2 col-md-2">
  <div class="dropdown w-100">
    <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownTipButton" data-bs-toggle="dropdown" aria-expanded="false">
      Kiválasztás
    </button>
    <ul class="dropdown-menu w-100" aria-labelledby="dropdownTipButton">
      <li v-for="item in tipOptions" :key="item.tip_az">
        <a class="dropdown-item" href="#" @click.prevent="selectTip(item)">{{ item.tip_megn }} - {{ item.tip_egyseg }}</a>
      </li>
    </ul>
  </div>
</div>

<!-- Eingabefeld -->
<div class="col-sm-1 col-md-1">
<input type="text" class="form-control" id="tip_filter" placeholder="Keresés">
</div>

<!-- Button -->
<div class="col-sm-1 col-md-1">
<button type="button" class="btn btn-secondary btn-sm" @click="fetchArTipus" style="width: 60px; text-align: center;">
  <i class="fas fa-sync-alt"></i>
</button>
</div>




<!-- Buttons für Standardwerte -->
<div class="col-sm-3 col-md-3">
  <div class="btn-group">
    <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValueTip(1)">Fuvar Ft/to</button>
    <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueTip(2)">Fuvar €/to</button>
  </div>
</div>
</div>



<!--    megrendeloi ár *********************************************************************************-->          
<div class="form-group row align-items-center">
<label for="megrendelo_ar" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megrendelői ár</label>
<div class="col-md-2">
  <input type="number" class="form-control" id="megrendelo_ar" step="0.01" v-model=megrendeles.m_aregysegar @focus="clear_ar">
</div>
</div>


<!-- Alvállalkozói ártípus ********************************************************************************************-->
<div class="form-group row align-items-center">
<label for="alvTip_filter" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Alváll. ártípus</label>

<!-- Schreibgeschütztes Eingabefeld -->
<div class="col-sm-3 col-md-2">
  <input type="text" class="form-control" v-model=megrendeles.mar_egysegarme readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
</div>

  <!-- Dropdown zur Auswahl -->
  <div class="col-sm-2 col-md-2">
  <div class="dropdown w-100">
    <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownAlvTipButton" data-bs-toggle="dropdown" aria-expanded="false">
      Kiválasztás
    </button>
    <ul class="dropdown-menu w-100" aria-labelledby="dropdownAlvTipButton">
      <li v-for="itemalv in alvTipOptions" :key="itemalv.tip_az">
        <a class="dropdown-item" href="#" @click.prevent="selectAlvTip(itemalv)">
          {{ itemalv.tip_megn }} - {{ itemalv.tip_egyseg }}
        </a>
      </li>
    </ul>
  </div>
</div>

<!-- Eingabefeld -->
<div class="col-sm-1 col-md-1">
<input type="text" class="form-control" id="alvTip_filter" placeholder="Keresés">
</div>

<!-- Button -->
<div class="col-sm-1 col-md-1">
<button type="button" class="btn btn-secondary btn-sm" @click="fetchAlvArTipus" style="width: 60px; text-align: center;">
  <i class="fas fa-sync-alt"></i>
</button>
</div>




<!-- Buttons für Standardwerte -->
<div class="col-sm-3 col-md-3">
  <div class="btn-group">
    <button type="button" class="btn btn-primary custom-btn-sm me-2" @click="setStandardValueAlvTip(1)">Fuvar Ft/to</button>
    <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueAlvTip(2)">Fuvar €/to</button>
  </div>
</div>
</div>




<!--    alv ár *********************************************************************************-->                      
<div class="form-group row align-items-center">
<label for="alv_dij" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Alapértelm. alv díj</label>
<div class="col-md-2">
  <input type="number" class="form-control" id="alv_dij" step="0.01" v-model=megrendeles.mar_egysegar @focus="clear_alvar">
</div>
</div>

<!-- megjegyzes ************************************************************************************************************ -->
<div class="form-group row align-items-center">
<label for="megjegyzes" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Megjegyzés</label>
<div class="col-md-8">
  <input type="text" class="form-control" id="megjegyzes" v-model=megrendeles.megjegyzes>
</div>
</div>


<!-- Belső hivatkozási szám ************************************************************************************************************ -->
<div class="form-group row align-items-center">
<label for="belsoHivatkozas" class="col-form-label col-sm-1 col-md-1 d-flex align-items-center">Belső hivatk.sz.</label>
<div class="col-md-2">
  <input type="text" class="form-control" id="belsoHivatkozas" v-model=megrendeles.belsoHivatkozas>
</div>
(Azonos értékhez tartozó fuvarok össze lesznek vezetve a VIR-ben.)
</div>


        <!-- Gombok -->
        <div class="form-group d-flex justify-content-center">
          <button type="button" class="btn btn-danger me-5" @click="cancel">Mégse</button>
          <button type="submit" class="btn btn-success">Mentés</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
    megrendeloOptions: [],
    selectedMegrendelo: '', // ID des ausgewählten Megrendelo
    selectedMegrendeloVnev1: '', // Name des ausgewählten Megrendelo
    megrendeles: null,
    cikkOptions: [],
    felrakoOptions: [],
    lerakoOptions: [],
    tipOptions: [],
    alvTipOptions: []
    };
  },
  computed: {
    ...mapGetters(['getorderData']),
    az() {
    return this.getorderData?.az || null;
  },
  gmpValue: {
  get() {
    // Konvertiere -1 in true und 0 in false
    return this.megrendeles.gmp == -1;
  },
  set(value) {
    // Konvertiere true in -1 und false in 0
    this.megrendeles.gmp = value ? -1 : 0;
  }
},
  },
  mounted() {
    console.log("Komponente geladen");
    this.fetchOrderDetails();
  },
  methods: {
    async fetchOrderDetails() {//**************************************************************************************** */
    const postData = {
      az: this.az
    };
    try {

      console.log('ez a fetchOrder',postData);
      const url = "/megrendeles/egy_megrendeles.php";
      const response = await axiosInstance.post(url, postData);

      if (response.status === 200 && response.data.data && response.data.data.length > 0) {
        this.megrendeles = response.data.data[0];
      } else {
        console.error('Fehler beim Abrufen der Daten:', response);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
    }
  },
  async fetchMegrendelo() {//********************************************************************************* */
    const v_nev1_filter = document.getElementById('megrendelo_filter').value;
const postData = {
  v_cegaz: this.megrendeles.m_cegaz,
  v_nev1_filter: v_nev1_filter
};
try {
  const url = "/megrendelo/list_megrendelo.php";
  const response = await axiosInstance.post(url, postData);
  if (response.status === 200) {
    this.megrendeloOptions = response.data.data; // Optionen aktualisieren
  } else {
    console.error("Fehler beim Abrufen der Megrendelő-Daten:", response.data.message);
  }
} catch (error) {
  console.error("Fehler bei der Anfrage:", error);
}
},


async  fetchCikk(){

const cikk_megn_filter = document.getElementById('cikk_filter').value;
const postData = {
  cikk_cegaz: this.megrendeles.m_cegaz,  // Fixierter Wert für v_cegaz
  cikk_megn_filter: cikk_megn_filter  // Dynamischer Wert aus dem Filter
 };
  //  console.log(JSON.stringify(postData))

  let url="/cikk/list_cikk.php";
  const response =await  axiosInstance.post(url, postData);
  if (response.status === 200) {
      this.cikkOptions = response.data.data;
   //   console.log(this.cikkOptions);
  } else {
      this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
  }
}, 
async  fetchFelhely(){
                  const hely_tavaz = this.selectedFelrako;  // document.getElementById('selectedFelrako').value;
                  const postData = {
                      hely_tavaz: hely_tavaz  // Dynamischer Wert aus dem Filter
                     };
                    console.log(JSON.stringify(postData))
                      let url="/hely/list_hely.php";
                      const response = await axiosInstance.post(url, postData);
                      console.log("response",response);
                      if (response.status === 200) {
                          this.felhelyOptions = response.data.data;
                       //   console.log(this.felhelyOptions);
                      } else {
                          this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                      }
               }, 

               async fetchLehely(){//**************************************************************************************** */
                  const Lhely_tavaz = this.selectedLerako;  // document.getElementById('selectedFelrako').value;
                  const postData = {
                      hely_tavaz: Lhely_tavaz  // Dynamischer Wert aus dem Filter
                     };
                  //  console.log(JSON.stringify(postData))

                  let url="/hely/list_hely.php";
                      const response = await axiosInstance.post(url, postData);
                      if (response.status === 200) {
                          this.lehelyOptions = response.data.data;
                       //   console.log(this.felhelyOptions);
                      } else {
                          this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                      }
               }, 
  async fetchFelrako(){
                  const tav_megn_filter = document.getElementById('felrako_filter').value;
                  const postData = {
                      tav_cegaz: this.megrendeles.m_cegaz,  // Fixierter Wert für v_cegaz
                      tav_megn_filter: tav_megn_filter  // Dynamischer Wert aus dem Filter
                     };
                  //  console.log(JSON.stringify(postData))

                      let url="/tav/list_tav.php";
                      const response =await axiosInstance.post(url, postData);
                      if (response.status === 200) {
                          this.felrakoOptions = response.data.data;
                         // console.log(this.felrakoOptions);
                      } else {
                          this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                      }

               }, 

               async  fetchLerako(){
                  const tav_megn_filterle = document.getElementById('lerako_filter').value;
                  const postData = {
                      tav_cegaz: this.megrendeles.m_cegaz,  // Fixierter Wert für v_cegaz
                      tav_megn_filter: tav_megn_filterle  // Dynamischer Wert aus dem Filter
                     };
                      //  console.log(JSON.stringify(postData))

                      let url="/tav/list_tav.php";
                      const response =await  axiosInstance.post(url, postData);
                      if (response.status === 200) {
                          this.lerakoOptions = response.data.data;
                       //   console.log(this.lerakoOptions);
                      } else {
                          this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                      }

               }, 
               async fetchArTipus() {//************************************************************************************ */
                  const tip_megn_filter = document.getElementById('tip_filter').value;
                  const postData = {
                      tip_cegaz: 1,  // Fixierter Wert für v_cegaz
                      tip_megn_filter: tip_megn_filter  // Dynamischer Wert aus dem Filter
                     };
                  //  console.log(JSON.stringify(postData))
                  let url="/tip/list_tip.php";
                      const response = await axiosInstance.post(url, postData);
                      if (response.status === 200) {
                          this.tipOptions = response.data.data;
                       //   console.log(this.felhelyOptions);
                      } else {
                          this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                      }
              },


              async fetchAlvArTipus() {//********************************************************************************* */
                      const alvTip_megn_filter = document.getElementById('alvTip_filter').value;
                      const postData = {
                          tip_cegaz: 1,  // Fester Wert für den Firmenkunden
                          tip_megn_filter: alvTip_megn_filter  // Wert aus dem Filter-Input
                      };
                      console.log(JSON.stringify(postData));  // Debugging

                      let url = "/tip/list_tip.php";  // API-Endpunkt
                      try {
                          const response = await axiosInstance.post(url, postData);
                          if (response.status === 200) {
                              this.alvTipOptions = response.data.data;  // Daten in die Option-Liste laden
                              console.log('Erfolgreich geladen:', this.alvTipOptions);  // Debugging
                          } else {
                              console.error('Fehler beim Laden der Daten:', response.data.message);
                              this.errorMessage = 'Fehler beim Abrufen der Daten: ' + response.data.message;
                          }
                      } catch (error) {
                          console.error('Fehler bei der Anfrage:', error);
                          this.errorMessage = 'Fehler beim Abrufen der Daten: ' + error.message;
                      }
                  },

  selectFelHely(item) {
this.megrendeles.fetchFelhely = `${item.hely_ceg} - ${item.hely_cim} - ${item.hely_megj}`;
},
clear_to() {
this.megrendeles.m_suly = null; // Mennyiség zurücksetzen
},

clear_km() {
this.megrendeles.m_km = null; // Távolság zurücksetzen
},

clear_ar() {
this.megrendeles.m_aregysegar = null; // Megrendelői ár zurücksetzen
},

selectLeHely(item) {
this.megrendeles.lehely = `${item.hely_ceg} - ${item.hely_cim} - ${item.hely_megj}`;
},


  selectCikk(item) {
    this.megrendeles.cikk = item.cikk_megn;
  },
  selectMegrendelo(item) {
    this.megrendeles.m_megrendeloaz = item.v_kod; // Setze die ID
    this.megrendeles.megrendelo = item.v_nev1; // Setze den Namen
},

  selectFelrako(item) {
    this.megrendeles.felrako = item.tav_megn;
  },
  selectLerako(item) {
    this.megrendeles.lerako = item.tav_megn;
  },
  selectTip(item) {
                this.megrendeles.m_artipaz = item.tip_az;
                this.megrendeles.m_egysegarme = `${item.tip_megn} - ${item.tip_egyseg}`;
          },
    selectAlvTip(itemalv) {//******************************************************** */
                    this.megrendeles.mar_artipaz = itemalv.tip_az; // Integer ID
                    this.megrendeles.mar_egysegarme = itemalv.tip_egyseg; // Einheitspreis als String
                },
  cancel() {
    if (confirm("Valóban be akarja bejezni az adatmegadást mentés nélkül?")) {
      this.$router.push('/osszesMegrendeles');
    }
  },
  saveOrder() {//************************************************************************************************** */
// Validierung der erforderlichen Felder   mentes
if (!this.megrendeles.m_az || !this.megrendeles.m_megrendeloaz || !this.megrendeles.m_felrakoaz || !this.megrendeles.m_lerakoaz) {
  alert("Bitte füllen Sie alle erforderlichen Felder aus.");
  return;
}

// Debugging: Konsolenausgabe der aktuellen Daten


const datax={
  m_megrendeloaz: this.megrendeles.m_megrendeloaz,
    megrendelo: this.megrendeles.megrendelo, // Megrendelo Name
  m_felrakoaz: this.megrendeles.m_felrakoaz,
    felrako: this.megrendeles.felrako,
  m_lerakoaz: this.megrendeles.m_lerakoaz,
    lerako: this.megrendeles.lerako,
  m_cikkaz: this.megrendeles.m_cikkaz,
    cikk: this.megrendeles.cikk,
  m_suly: this.megrendeles.m_suly,
  m_km: this.megrendeles.m_km,
  m_felhelyaz: this.megrendeles.m_felhelyaz || 0, // Fallback zu 0, falls leer
    fhely:this.megrendeles.fhely,
  m_lehelyaz: this.megrendeles.m_lehelyaz || 0,  // Fallback zu 0, falls leer
    lhely:this.megrendeles.lhely,
  hivatkozasi_szam: this.megrendeles.hivatkozasi_szam,
  m_artipaz: this.megrendeles.m_artipaz,
  m_aregysegar: this.megrendeles.m_aregysegar,
  mar_egysegar: this.megrendeles.mar_egysegar,
  gmp: this.megrendeles.gmp ? -1 : 0, // GMP-Wert (Ja = -1, Nein = 0)
  megjegyzes: this.megrendeles.megjegyzes,
  belsoHivatkozas: this.megrendeles.belsoHivatkozas
}

const postData = {
  az:this.az,
  data:datax
};

console.log("Daten zum Speichern:", postData);

// Senden der Daten an den Server
axiosInstance.post('/megrendeles/api_update_megrendeles.php', postData)
  .then(response => {
    if (response.data.success) {
     // alert(" wurden erfolgreich gespeichert.Die Änderungen");
      console.log("Antwort vom Server:", response.data);

      // Weiterleitung zur Übersichtsseite
      this.$router.push('/osszesMegrendeles');
    } else {
      console.error("Fehler beim Speichern:", response.data.message);
      alert("Hiba a mentés során: " + response.data.message);
    }
  })
  .catch(error => {
    if (error.response) {
      console.error("Server-válaszhiba:", error.response);
      alert("Hiba a mentés során: " + error.response.data.message);
    } else if (error.request) {
      console.error("Keine Antwort vom Server:", error.request);
      alert("A szerver nem válaszol. Kérem ismételje meg később.");
    } else {
      console.error("Fehler bei der Anfrage:", error.message);
      alert("Váratlan hiba történt: " + error.message);
    }
  });
},

  setStandardValue(index) {//**************************************************************************************** */
let standardValue;
switch (index) {
  case 1:
    standardValue = { v_kod: "1", v_nev1: "Viterra" };
    break;
  case 2:
    standardValue = { v_kod: "2", v_nev1: "Cargill" };
    break;
  case 3:
    standardValue = { v_kod: "3", v_nev1: "UBM Grain" };
    break;
  default:
    standardValue = null;
}
if (standardValue) {
  this.megrendeles.megrendelo = standardValue.v_nev1;
}
},
  setStandardValueCikk(standardIndex) {//**************************************************************************** */
    this.cikkOptions = [];
    let standardValue;
    switch (standardIndex) {
      case 1:
        standardValue = {
          cikk_az: '42',
          cikk_megn: 'szójadara'
        };
        this.megrendeles.cikk = standardValue.cikk_megn;
        break;
      case 2:
        standardValue = {
          cikk_az: '22',
          cikk_megn: 'kukorica'
        };
        this.megrendeles.cikk = standardValue.cikk_megn;
        break;
      case 3:
        standardValue = {
          cikk_az: '284',
          cikk_megn: 'napradara'
        };
        this.megrendeles.cikk = standardValue.cikk_megn;
        break;
      default:
        standardValue = null;
    }
  },
  setStandardValueFelrako(standardIndex) {//***************************************************** */
                                  this.felrakoOptions = [];
                                  let standardValue;

                                  switch (standardIndex) {
                                      case 1:
                                          standardValue = {
                                              az: '53',
                                              tav_az: '125',
                                              tav_megn: 'Slo-Koper',
                                              tav_cegaz: '1'
                                          };
                                          this.megrendeles.m_felrakoaz = '125';
                                          this.megrendeles.felrako = standardValue.tav_megn;
                                          break;
                                      case 2:
                                          standardValue = {
                                              az: '1352',
                                              tav_az: '1516',
                                              tav_megn: 'H-Foktő',
                                              tav_cegaz: '1'
                                          };
                                          this.megrendeles.m_felrakoaz = '1516';
                                          this.megrendeles.felrako = standardValue.tav_megn;
                                          break;
                                      case 3:
                                          standardValue = {
                                              az: '72',
                                              tav_az: '153',
                                              tav_megn: 'H-Szabadegyháza',
                                              tav_cegaz: '1'
                                          };
                                          this.megrendeles.m_felrakoaz = '153';
                                          this.megrendeles.felrako = standardValue.tav_megn;
                                          break;
                                      default:
                                          standardValue = null;
                                  }
                                  if (standardValue) {
                                      this.felrakoOptions.push(standardValue);
                                  }
                              },
                              setStandardValueLerako(standardIndex) {//********************************************************************* */
                                    this.lerakoOptions = [];
                                    let standardValue;

                                    switch (standardIndex) {
                                        case 1:
                                            standardValue = {
                                                az: '248',
                                                tav_az: '382',
                                                tav_megn: 'H-Dunaföldvár',
                                                tav_cegaz: '1'
                                            };
                                            this.megrendeles.m_lerakoaz = '382';
                                            this.megrendeles.lerako = standardValue.tav_megn;  // Name setzen
                                            break;
                                        case 2:
                                            standardValue = {
                                                az: '72',
                                                tav_az: '153',
                                                tav_megn: 'H-Szabadegyháza',
                                                tav_cegaz: '1'
                                            };
                                            this.megrendeles.m_lerakoaz = '153';
                                            this.megrendeles.lerako = standardValue.tav_megn;  // Name setzen
                                            break;
                                        case 3:
                                            standardValue = {
                                                az: '205',
                                                tav_az: '335',
                                                tav_megn: 'H-Kiskunfélegyháza',
                                                tav_cegaz: '1'
                                            };
                                            this.megrendeles.m_lerakoaz = '335';
                                            this.megrendeles.lerako = standardValue.tav_megn;  // Name setzen
                                            break;
                                        default:
                                            standardValue = null;
                                    }
                                    if (standardValue) {
                                        this.lerakoOptions.push(standardValue);
                                    }
                                },
                                setStandardValueTip(standardIndex) {//***************************************************************** */
                                                      this.tipOptions = [];
                                                      let standardValue;

                                                      switch (standardIndex) {
                                                          case 1:
                                                              standardValue = {
                                                                  az: '2',
                                                                  tip_az: '1',
                                                                  tip_megn: 'Fuvar',
                                                                  tip_egyseg: 'Ft/to',
                                                                  tip_cegaz: '1',
                                                                  m_tipmenny:1
                                                              };
                                                              this.megrendeles.m_artipaz = '1';
                                                              this.megrendeles.m_egysegarme = `${standardValue.tip_megn} - ${standardValue.tip_egyseg}`; // Name und Einheit setzen
                                                              break;
                                                          case 2:
                                                              standardValue = {
                                                                  az: '4',
                                                                  tip_az: '3',
                                                                  tip_megn: 'Fuvar',
                                                                  tip_egyseg: '€/to',
                                                                  tip_cegaz: '1',
                                                                  m_tipmenny:1
                                                              };
                                                              this.megrendeles.m_artipaz = '3';
                                                              this.megrendeles.m_egysegarme = `${standardValue.tip_megn} - ${standardValue.tip_egyseg}`; // Name und Einheit setzen
                                                              break;
                                                          default:
                                                              standardValue = null;
                                                      }
                                                      if (standardValue) {
                                                          this.tipOptions.push(standardValue);
                                                      }
                                                  },
                                       setStandardValueAlvTip(standardIndex) {//****************************************************** */
                                                this.alvTipOptions = [];
                                                let standardValue;

                                                switch (standardIndex) {
                                                    case 1:
                                                        standardValue = {
                                                            az: '2',
                                                            tip_az: '1',
                                                            tip_megn: 'Fuvar',
                                                            tip_egyseg: 'Ft/to',
                                                            tip_cegaz: '1',
                                                            m_tipmenny:1
                                                        };
                                                        this.megrendeles.mar_artipaz = standardValue.tip_az; // Setze die ID
                                                        this.megrendeles.mar_egysegarme = standardValue.tip_egyseg; // Setze die Einheit
                                                        break;
                                                    case 2:
                                                        standardValue = {
                                                            az: '4',
                                                            tip_az: '3',
                                                            tip_megn: 'Fuvar',
                                                            tip_egyseg: '€/to',
                                                            tip_cegaz: '1',
                                                            m_tipmenny:1
                                                        };
                                                        this.megrendeles.mar_artipaz = standardValue.tip_az; // Setze die ID
                                                        this.megrendeles.mar_egysegarme = standardValue.tip_egyseg; // Setze die Einheit
                                                        break;
                                                    default:
                                                        standardValue = null;
                                                }
                                                if (standardValue) {
                                                    this.alvTipOptions.push(standardValue);
                                                }
                                            }


}
};
</script>

<style scoped>
.container {
margin-top: 20px;
}



.dropdown-menu {
  max-height: 500px; /* Maximale Höhe der Dropdown-Liste */
  overflow-y: auto; /* Aktiviert die vertikale Scrollbar */
  overflow-x: hidden; /* Verhindert horizontales Scrollen */
  max-width: 100%; /* Breite der Dropdown-Liste bleibt gleich */
  white-space: nowrap; /* Kein Zeilenumbruch innerhalb der Items */
  text-overflow: ellipsis; /* Textüberlauf mit "..." anzeigen */
}

.dropdown-item {
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
}

label {
font-weight: bold; /* Labels hervorheben */
white-space: nowrap; /* Kein Zeilenumbruch */
overflow: hidden; /* Überlaufenden Text ausblenden */
text-overflow: ellipsis; /* Zeige "..." für überlaufenden Text */
}

.form-control:focus, .dropdown-toggle:focus {
border-color: #007bff; /* Bootstrap-Blau */
box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}


.form-group.row {
margin-bottom: 4px; /* Einheitliche Abstände zwischen den Gruppen */
align-items: center; /* Vertikale Zentrierung */
}

.btn-group button, .dropdown-toggle {
height: 36px; /* Einheitliche Buttonhöhe */
padding: 6px 12px; /* Standard-Padding */
font-size: 14px; /* Einheitliche Schriftgröße */
}

.input-group .form-control {
height: auto; /* Automatische Höhe für Eingabefelder */
}

.container-fluid {
padding: 0 16px; /* Innenabstände reduzieren */
}


.order-details ul {
list-style-type: none;
padding: 0;
}

.order-details ul li {
margin-bottom: 10px;
}

.btn-container {
display: flex;
justify-content: flex-start;
gap: 10px;
}

input[type="checkbox"][disabled] {
opacity: 0.8;
border: 1px solid #ccc;
}

.custom-btn-sm {
font-size: 12px;
padding: 4px 8px;
}

.lock-icon {
  display: flex;
  align-items: center;
}

.form-control[readonly] {
  background-color: #e9ecef;
  border-color: #ced4da;
  color: #495057;
  cursor: not-allowed;
}

input[readonly] {
background-color: #e9ecef; /* Leicht grauer Hintergrund */
border-color: #ced4da; /* Standard-Rahmenfarbe */
color: #495057; /* Textfarbe */
cursor: not-allowed; /* Zeige an, dass es nicht editierbar ist */
}

input[readonly]:focus {
outline: none; /* Entfernt den Fokusrahmen für readonly-Felder */
box-shadow: none; /* Kein Schatten bei Fokus */
}

</style>

