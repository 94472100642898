import { createStore } from 'vuex'

// Enum für Benutzertypen
export const UserTypes = Object.freeze({
  ADMIN: 'admin',
  FUVAR_SZERVEZŐ: 'fuvarszervező',
  DISZPÉCSER: 'diszpécser'
});


export default createStore({
  state: {
    userData: {  
      szem_az: '',
      nev: '',
      fsz: '',
      ceg_az: '',
      first_sign: '',
      tipus: '',
      csoport: ''
    },

    wochen_auswahl: {
      ceg_az: null, // Aktuelle Firma
      fsz: null,    // Benutzer-Fuvarszervező
      currentYear: null, // Aktuelles Jahr
      currentWeek: null, // Aktuelle Woche
    },

    order: {
      az: null,
      m_cegaz: null,
      datum: null,
      viszonylat: null,
      napifo_az: null,
      napimellek_az: null,
      tipus: null,
      megrendelo: null,
      cikk: null,
      szall_hatarido: null,
      hivatkozasi_szam: null,
      oldal:null,
      fo_megjegyzes:null,
      csoport:null,
      fsz:null, 
      gmp:null,
      m_megrendeloaz:null,
      m_felrakoaz:null,
      m_lerakoaz:null,
      m_cikkaz:null,
      belsoHivatkozas:null
      
    },
    
    napi_datum: null,

    logParams: {  // Neue State für die ChangeLog-Parameter
      user: '',
      table_name: '',
      record_id: 0
    },
    truckData: {
      az: null,
      fo_az: null,
      rendsz_az: null,
      rendszam: '',
      potkocsi: '',
      felsegjel: '',
      fuvarozo: '',
      felrakas_napja: '',
      lerakas_napja: '',
      fel_suly: null,
      le_suly: null,
      rakodasi_szam: '',
      billenes_kod: '',
      fuv_az: null,
      datum: '',
      megjegyzes: '',
      sorrend: null,
      status: null,
      storno: null,
      made: '',
      felh: '',
      timest: '',
      mod_felh: '',
      sajat: null,
      fordulo: null,
      ev: null,
      ho: null,
      het: null,
      het_napja: null,
      m_tipmenny: null,
      ceg_az: null,
      alv_artipaz:null,
      alv_egysegar:null,
      eltero_ar:null,
      alv_egysegarme:null
      
    }
   
  },
  getters: {
    getWochenAuswahl: (state) => state.wochen_auswahl,
    getTruckData: state => state.truckData,
    getUserData: state => state.userData,
    getorderData: state => state.order,
    getUserType: state => state.userData.tipus,  // Getter für den Benutzertyp
    isAdmin: state => state.userData.tipus === UserTypes.ADMIN,  // Prüfen, ob der Benutzer ein Admin ist
    isFuvarszervezo: state => state.userData.tipus === UserTypes.FUVAR_SZERVEZŐ,  // Prüfen, ob der Benutzer ein Fuvarszervező ist
    isDiszpecser: state => state.userData.tipus === UserTypes.DISZPÉCSER,  // Prüfen, ob der Benutzer ein Diszpécser ist
    getLogParams: state => state.logParams,  // Getter für die ChangeLog-Parameter
    getNapiDatum: state => state.napi_datum  // Getter, um das Datum abzurufen
    
    
  },
  mutations: {
    setTruckData(state, truckData) {
      state.truckData = truckData;
    },
    setWochenAuswahl(state, payload) {
      state.wochen_auswahl = payload;
    },
    setNapiDatum(state, newDate) {
      if (newDate) {
        console.log('Altes napiDatum:', state.napi_datum);
        console.log('Neues napiDatum:', newDate);
        state.napi_datum = newDate;
      } else {
        console.warn('Undefiniertes oder null-Datum wurde übergeben. Änderung abgelehnt.');
      }
    },
      // Neue Mutation, um Truck-Daten zu leeren
  clearTruckData(state) {
    state.truckData = {
      az: null,
      fo_az: null,
      rendsz_az: null,
      rendszam: null,
      potkocsi: null,
      felsegjel: null,
      fuvarozo: null,
      felrakas_napja: null,
      lerakas_napja: null,
      fel_suly: null,
      le_suly: null,
      rakodasi_szam: null,
      billenes_kod: null,
      fuv_az: null,
      datum: null,
      megjegyzes: null,
      sorrend: null,
      status: null,
      storno: null,
      made: null,
      felh: null,
      timest: null,
      mod_felh: null,
      sajat: null,
      fordulo: null,
      ev: null,
      ho: null,
      het: null,
      het_napja: null,
      m_tipmenny: null,
      ceg_az: null,
      alv_artipaz:null,
      alv_egysegar:null,
      eltero_ar:null,
      alv_egysegarme:null


    };
  },
  setUserData(state, userData) {
      state.userData.szem_az = userData.szem_az;
      state.userData.nev = userData.nev;
      state.userData.fsz = userData.fsz;
      state.userData.ceg_az = userData.ceg_az;
      state.userData.first_sign = userData.firstSign;
      state.userData.tipus = userData.tipus;
      state.userData.csoport = userData.csoport;
    },
    setorderData(state, orderData) {
      state.order = orderData;
      console.log('orderData', orderData);
    },
    setLogParams(state, logParams) {  // Neue Mutation zum Setzen der ChangeLog-Parameter
      state.logParams = logParams;
    }
      },
  actions: {
    saveWochenAuswahl({ commit }, auswahl) {
      commit("setWochenAuswahl", auswahl);
    }
    
  },
  modules: {
    // Falls du dein Store in Module unterteilen möchtest
  }


})
