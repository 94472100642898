<template>
  <navigation-bar></navigation-bar>

  <div class="email-container">
    <!-- Linke Seite: Liste der E-Mails -->
    <div class="email-list">
      <h4> <br> </h4>
      <table>
        <thead>
          <tr>
            <th>Absender E-Mail</th>
            <th>Absender Name</th>
            <th>Betreff</th>
            <th>Empfangen am</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="email in emails"
            :key="email.id"
            @click="selectEmail(email)"
            :class="{ selected: selectedEmail && selectedEmail.id === email.id }"
          >
            <td>{{ email.from_email }}</td>
            <td>{{ email.from_name }}</td>
            <td>{{ email.subject }}</td>
            <td>{{ email.receivedDateTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Rechte Seite: Details der ausgewählten E-Mail -->
    <div class="email-details" v-if="selectedEmail">
      <h4> <br> </h4>
      <div><strong>Von:</strong> {{ selectedEmail.from_name }} ({{ selectedEmail.from_email }})</div>
      <div><strong>An:</strong> {{ selectedEmail.to_email }}</div>
      <div><strong>CC:</strong> {{ selectedEmail.cc_email || 'Keine' }}</div>
      <div><strong>Empfangen am:</strong> {{ selectedEmail.receivedDateTime }}</div>
      <div><strong>Anhänge:</strong> {{ selectedEmail.attachments || 'Keine' }}</div>
      <div class="email-body">
        <div v-html="sanitizedHtml"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';
import DOMPurify from 'dompurify';

export default {
  data() {
    return {
      emails: [],
      selectedEmail: null,
      errorMessage: '',
      loading: false,
    };
  },
  computed: {
    sanitizedHtml() {
      return this.selectedEmail && this.selectedEmail.bodyHtml
        ? DOMPurify.sanitize(this.selectedEmail.bodyHtml)
        : '';
    },
  },
  methods: {
    async fetchEmails() {
      this.loading = true;
      const postData = {
        fsz: "bonyhad",
        ceg_az: 1
      };

      try {
        const url = '/emails/list_emails.php';
        const response = await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data && response.data.data.length > 0) {
          this.emails = response.data.data;
          this.selectedEmail = this.emails[0]; // Wählt automatisch die erste E-Mail aus
        } else {
          this.errorMessage = 'Fehler beim Abrufen der E-Mails: ' + response.statusText;
        }
      } catch (error) {
        this.errorMessage = 'Fehler beim Abrufen der E-Mails: ' + error.message;
      } finally {
        this.loading = false;
      }
    },
    selectEmail(email) {
      this.selectedEmail = email;
    },
  },
  mounted() {
    this.fetchEmails();
  },
};
</script>

<style>

.email-container {
  display: flex;
  gap: 20px; /* Abstand zwischen Liste und Details */
  height: 100%; /* Damit der Container immer die volle Höhe einnimmt */
}


.email-list, .email-details {
  flex: 1; /* Beide Seiten nehmen gleichmäßig Platz ein */
  min-width: 50%; /* Garantiert gleiche Breite */
  max-width: 50%; /* Verhindert Überschreiten der Breite */
  overflow: auto; /* Fügt Scrollbars hinzu, falls Inhalt zu groß ist */
  box-sizing: border-box; /* Padding und Border zählen zur Breite */
}

.email-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Alle Spalten gleich breit */
}

.email-list th,
.email-list td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Kein Zeilenumbruch */
  overflow: hidden; /* Verhindert Überlaufen des Texts */
  text-overflow: ellipsis; /* Zeigt "..." bei zu langem Text */
}

.email-list tr:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.email-list .selected {
  background-color: #dfeeff;
}

.email-details div {
  margin-bottom: 10px;
  text-align: left; /* Stellt sicher, dass alle Texte linksbündig sind */
}


.email-body {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  overflow: auto;
  max-height: 400px;
  text-align: left; /* HTML-Inhalt ebenfalls linksbündig */
}

.error-message {
  color: red;
  font-weight: bold;
  margin: 10px 0;
}

.loading-spinner {
  text-align: center;
  font-size: 1.2em;
  color: #007bff;
  margin: 20px 0;
}


</style>
